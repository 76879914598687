"use client";
import React, { useState, useEffect } from "react";
import IconComponent from "./IconComponent";

type CountdownTimerProps = {
  website: string; // Add website as a prop
};

export const CountdownTimer = (props: CountdownTimerProps) => {
  const { website } = props;

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateCountdown = () => {
      const now = new Date();
      const targetDate = getNextMonday1PMUTC();

      const timeLeft = targetDate.getTime() - now.getTime();

      if (timeLeft <= 0) {
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return false; // Signal that the countdown has reached the target
      } else {
        setCountdown({
          days: Math.floor(timeLeft / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((timeLeft % (1000 * 60)) / 1000),
        });
        return true; // Signal that the countdown is still ongoing
      }
    };

    const interval = setInterval(() => {
      const isCountdownActive = calculateCountdown();
      if (!isCountdownActive) {
        clearInterval(interval);
        setTimeout(() => {
          calculateCountdown();
          setInterval(calculateCountdown, 1000);
        }, 1000);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col items-center text-white font-popping">
      {/* <div className="font-bold text-center mb-4">
        <span className="text-red-700 animate-pulse">NEW </span>
        LEADERBOARD STARTS IN
      </div> */}

      <div className="font-bold text-center mb-4 flex items-center justify-center">
        <span className="text-red-700 animate-pulse">NEW</span>
        <span className="ml-2">LEADERBOARD STARTS IN</span>
        {website === "roobet" && (
          <IconComponent className="ml-1 w-4 h-4 text-red-700" />
        )}
      </div>

      <div className="flex items-center w-full justify-center">
        <div className="border-t border-white flex-grow max-w-[16rem]"></div>
        <div className="flex space-x-4 mx-4">
          <div className="flex flex-col items-center bg-black bg-opacity-75 p-4 rounded-full shadow-red w-20 h-20 justify-center">
            <span className="text-3xl text-white">{countdown.days}</span>
            <span className="text-sm text-red-700">DAYS</span>
          </div>
          <div className="flex flex-col items-center bg-black bg-opacity-75 p-4 rounded-full shadow-red w-20 h-20 justify-center">
            <span className="text-3xl text-white">{countdown.hours}</span>
            <span className="text-sm text-red-700">HRS</span>
          </div>
          <div className="flex flex-col items-center bg-black bg-opacity-75 p-4 rounded-full shadow-red w-20 h-20 justify-center">
            <span className="text-3xl text-white">{countdown.minutes}</span>
            <span className="text-sm text-red-700">MIN</span>
          </div>
          <div className="flex flex-col items-center bg-black bg-opacity-75 p-4 rounded-full shadow-red w-20 h-20 justify-center">
            <span className="text-3xl text-white">{countdown.seconds}</span>
            <span className="text-sm text-red-700">SEC</span>
          </div>
        </div>
        <div className="border-t border-white flex-grow max-w-[16rem]"></div>
      </div>
    </div>
  );
};

const getNextMonday1PMUTC = () => {
  const now = new Date();
  const dayOfWeek = now.getUTCDay();
  const daysUntilNextMonday = (8 - dayOfWeek) % 7 || 7; // Ensure it correctly handles Sunday

  const nextMonday = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate() + daysUntilNextMonday,
      0,
      0,
      0,
      0
    )
  );

  return nextMonday;
};
