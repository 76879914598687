"use client";

import React, { useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Image as NextImage,
  Chip,
} from "@nextui-org/react";
import { LeaderboardEntry } from "../_types";

type LeaderboardCardProps = {
  data: LeaderboardEntry;
  prize: String;
  position: number;
  className?: React.HTMLAttributes<HTMLDivElement>;
  website: string;
};

export const FancyCard = (props: LeaderboardCardProps) => {
  const { data, position, prize, website } = props;
  const cardRef = useRef<HTMLDivElement>(null);
  const wagered = data.wagered;

  const handleMouseMove = (e: React.MouseEvent) => {
    const card = cardRef.current;
    if (!card) return;

    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left - rect.width / 2;
    const y = e.clientY - rect.top - rect.height / 2;

    card.style.transform = `perspective(1000px) rotateY(${x / 10}deg) rotateX(${y / 10}deg)`;
  };

  const handleMouseLeave = () => {
    const card = cardRef.current;
    if (!card) return;

    card.style.transform = "perspective(1000px) rotateY(0deg) rotateX(0deg)";
  };

  const getOrder = (position: number) => {
    if (position === 1) return "order-1 sm:order-2 sm:-translate-y-8";
    if (position === 2) return "order-2 sm:order-1";
    if (position === 3) return "order-3";
  };

  let avatarSrc = data.avatar;

  if (
    avatarSrc === "/assets/csgo/avatar-anonymous.png" ||
    avatarSrc === "https://avatars.steamstatic.com/null_full.jpg" ||
    avatarSrc === "" ||
    avatarSrc === "/assets/common/avatar-anonymous.png"
  ) {
    avatarSrc = "/brands/nickel_pfp.webp";
  }

  const currencyImageSrc =
    website === "roobet"
      ? "/currency/roobet_gem.svg"
      : website === "clash"
        ? "/gems/gif/red.gif"
        : website === "bcgames"
          ? "/currency/bcgames.webp"
          : website === "cases"
            ? "/currency/cases.svg"
            : "/gems/gif/yellow.gif";

  return (
    <div className={`flex justify-center my-4 ${getOrder(position)}`}>
      <Card
        ref={cardRef}
        className={`border-hidden font-popping shadow-2xl w-[300px] h-[400px] pt-6 rounded-lg overflow-hidden transform transition-transform duration-300 ease-out`}
        style={{
          background: `linear-gradient(135deg, ${
            position === 1
              ? "rgba(255, 215, 0, 1) 0%, rgba(255, 223, 0, 0.7) 100%"
              : position === 2
                ? "rgba(192, 192, 192, 1) 0%, rgba(211, 211, 211, 0.7) 100%"
                : "rgba(205, 127, 50, 1) 0%, rgba(210, 140, 60, 0.7) 100%"
          })`,
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.6)",
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <div className="relative text-red-600">
          <CardHeader className="flex items-center pb-6 justify-center bg-opacity-50 relative">
            <div className="relative">
              <NextImage
                src={avatarSrc}
                alt="avatar"
                className="border-4 border-black shadow-red rounded-full"
                width={100}
                height={100}
              />
              {position === 1 && (
                <img
                  src="/assets/golden_crown.png"
                  alt="Crown"
                  className="absolute"
                  style={{
                    top: "-35px",
                    width: "150%",
                    height: "75%",
                    left: "75%",
                    transform: "translateX(-50%) rotate(30deg)",
                    zIndex: 20,
                  }}
                />
              )}
            </div>
          </CardHeader>
        </div>
        <CardBody className="text-center font-popping bg-black bg-opacity-75 p-4">
          <h2 className="text-2xl font-extrabold text-red-600 uppercase mb-2">
            <span className="text-white truncate w-[80%] inline-block align-middle">
              {data.name}
            </span>
          </h2>
          <div className="flex items-center justify-center my-2">
            <div className="flex-grow border-b border-white"></div>
            <div className="relative flex items-center justify-center w-6 h-6 mx-2">
              <NextImage
                src={currencyImageSrc}
                alt="Currency Image"
                width={24}
                height={24}
                className="inline-block"
              />
            </div>
            <div className="flex-grow border-b border-white"></div>
          </div>
          <div className="flex items-center justify-center mt-2">
            <div className="text-center text-white text-sm font-thin">
              <a className="font-blackops">Wagered</a>
              <br />
              <div className="pt-1">
                <Chip
                  startContent={
                    <NextImage
                      src={currencyImageSrc}
                      alt="Currency Image"
                      width={24}
                      height={24}
                      className="pr-1 inline-block"
                    />
                  }
                  variant="faded"
                  color="success"
                >
                  {wagered.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Chip>
              </div>
            </div>
          </div>
          <div className="flex items-center pt-2 justify-center mt-2">
            <div className="text-center text-white text-small font-thin">
              <a className="font-blackops">Reward</a>
              <br />
              <Chip
                startContent={
                  <NextImage
                    src={currencyImageSrc}
                    alt="Currency Image"
                    width={24}
                    height={24}
                    className="pr-1 inline-block"
                  />
                }
                variant="faded"
                color="success"
              >
                <a className="">{prize}</a>
              </Chip>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
