// components/ui/BackgroundGradientAnimation.tsx
"use client";
import { cn } from "@nextui-org/react";
import { motion } from "framer-motion";
import React from "react";

export const BackgroundGradientAnimation = ({
  children,
  className,
  containerClassName,
  animate = true,
}: {
  children?: React.ReactNode;
  className?: string;
  containerClassName?: string;
  animate?: boolean;
}) => {
  const variants = {
    initial: { backgroundPosition: "0% 50%" },
    animate: {
      backgroundPosition: [
        "0% 50%",
        "100% 50%",
        "100% 100%",
        "0% 100%",
        "0% 50%",
      ],
    },
  };

  return (
    <div className={cn("relative p-[4px] group", containerClassName)}>
      <motion.div
        variants={animate ? variants : undefined}
        initial={animate ? "initial" : undefined}
        animate={animate ? "animate" : undefined}
        transition={
          animate
            ? { duration: 10, repeat: Infinity, ease: "linear" }
            : undefined
        }
        style={{ backgroundSize: animate ? "200% 200%" : undefined }}
        className={cn(
          "absolute inset-0 rounded-3xl z-[1] opacity-100 blur-xl transition duration-500 will-change-transform",
          "bg-[radial-gradient(circle_farthest-side_at_0_100%,#DC2626,transparent),radial-gradient(circle_farthest-side_at_100%_0,#DC2626,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#DC2626,transparent),radial-gradient(circle_farthest-side_at_0_0,#DC2626,#DC2626)]"
        )}
      />
      <motion.div
        variants={animate ? variants : undefined}
        initial={animate ? "initial" : undefined}
        animate={animate ? "animate" : undefined}
        transition={
          animate
            ? { duration: 10, repeat: Infinity, ease: "linear" }
            : undefined
        }
        style={{ backgroundSize: animate ? "200% 200%" : undefined }}
        className={cn(
          "absolute inset-0 rounded-3xl z-[1] will-change-transform",
          "bg-[radial-gradient(circle_farthest-side_at_0_100%,#000000,transparent),radial-gradient(circle_farthest-side_at_100%_0,#000000,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#000000,transparent),radial-gradient(circle_farthest-side_at_0_0,#000000,#000000)]"
        )}
      />
      <div className={cn("relative z-10", className)}>{children}</div>
    </div>
  );
};

// // components/ui/BackgroundGradientAnimation.tsx
// "use client";
// import { cn } from "@nextui-org/react";
// import { motion } from "framer-motion";
// import React from "react";

// export const BackgroundGradientAnimation = ({
//   children,
//   className,
//   containerClassName,
//   animate = true,
// }: {
//   children?: React.ReactNode;
//   className?: string;
//   containerClassName?: string;
//   animate?: boolean;
// }) => {
//   const variants = {
//     initial: { backgroundPosition: "0 50%" },
//     animate: { backgroundPosition: ["0, 50%", "100% 50%", "0 50%"] },
//   };

//   return (
//     <div className={cn("relative p-[4px] group", containerClassName)}>
//       <motion.div
//         variants={animate ? variants : undefined}
//         initial={animate ? "initial" : undefined}
//         animate={animate ? "animate" : undefined}
//         transition={
//           animate
//             ? { duration: 5, repeat: Infinity, repeatType: "reverse" }
//             : undefined
//         }
//         style={{ backgroundSize: animate ? "400% 400%" : undefined }}
//         className={cn(
//           "absolute inset-0 rounded-3xl z-[1] opacity-60 group-hover:opacity-100 blur-xl transition duration-500 will-change-transform",
//           "bg-[radial-gradient(circle_farthest-side_at_0_100%,#00ccb1,transparent),radial-gradient(circle_farthest-side_at_100%_0,#7b61ff,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#ffc414,transparent),radial-gradient(circle_farthest-side_at_0_0,#1ca0fb,#141316)]"
//         )}
//       />
//       <motion.div
//         variants={animate ? variants : undefined}
//         initial={animate ? "initial" : undefined}
//         animate={animate ? "animate" : undefined}
//         transition={
//           animate
//             ? { duration: 5, repeat: Infinity, repeatType: "reverse" }
//             : undefined
//         }
//         style={{ backgroundSize: animate ? "400% 400%" : undefined }}
//         className={cn(
//           "absolute inset-0 rounded-3xl z-[1] will-change-transform",
//           "bg-[radial-gradient(circle_farthest-side_at_0_100%,#00ccb1,transparent),radial-gradient(circle_farthest-side_at_100%_0,#7b61ff,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#ffc414,transparent),radial-gradient(circle_farthest-side_at_0_0,#1ca0fb,#141316)]"
//         )}
//       />
//       <div className={cn("relative z-10", className)}>{children}</div>
//     </div>
//   );
// };
