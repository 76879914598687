"use client";
import React, { useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  User,
} from "@nextui-org/react";
import Image from "next/image";
import { LeaderboardEntry } from "./_types"; // Adjust this path as needed
import { Meteors } from "../../components/Meteors";

type LeaderboardProps = {
  data: LeaderboardEntry[];
  prize: String[];
  website: string;
};

export const PreviousLeaderboardButton = (props: LeaderboardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data, prize, website } = props;
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const currencyImageSrc =
    website === "roobet"
      ? "/currency/roobet_gem.svg"
      : website === "rustmagic"
        ? "/currency/rustmagic_coin.svg"
        : website === "cases"
          ? "/currency/cases.svg"
          : "/gems/gif/red.gif";

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <Button
        onPress={openModal}
        className="bg-transparent text-white font-blackops font-bold w-52 h-6 rounded-md flex items-center justify-center shadow-sm transition-all duration-300 ease-in-out"
        style={{
          boxShadow: "0 0 15px rgba(255, 0, 0, 0.5)", // Pink shadow
        }}
      >
        <div className="flex items-center justify-center w-full">
          <span className="text-white font-bold">View Previous Winners</span>
        </div>
      </Button>
      <Modal
        backdrop="opaque"
        isOpen={isOpen}
        size={"4xl"} // Consider changing the size if needed
        onClose={closeModal}
        radius="lg"
        classNames={{
          body: "py-6 overflow-hidden scrollbar-hide", // Removed overflow-y-auto
          backdrop: "backdrop-blur-xl", // This class should apply the blur effect
          base: "border-[#292f46] bg-gradient-to-b from-red-950 to-black text-[#a8b0d3] md-full shadow-lg",
          header: "border-b-[1px] border-red-700",
          footer: "border-t-[1px] border-red-700",
          closeButton: "hover:bg-white/5 active:bg-white/10",
        }}
        style={{
          boxShadow: "0 0 30px 10px rgba(255, 0, 0, 0.75)",
        }}
      >
        <ModalContent>
          <>
            <ModalHeader className="flex flex-col gap-1 font-popping text-white text-center ">
              Nickel's {capitalizeFirstLetter(website)} Previous Leaderboard
            </ModalHeader>
            <ModalBody className="max-h-[60vh] overflow-y-auto scrollbar-hide">
              {" "}
              {/* Custom scrollbar-hide class */}
              <Table
                removeWrapper
                aria-label="Leaderboard table"
                className="w-full font-popping"
              >
                <TableHeader className="font-blackops">
                  <TableColumn className="w-16 bg-tranparent text-white font-extrabold">
                    Position
                  </TableColumn>
                  <TableColumn className="w-16 font-extrabold text-white bg-tranparent">
                    Name
                  </TableColumn>
                  <TableColumn className="w-16 font-extrabold text-white bg-tranparent">
                    Wagered
                  </TableColumn>
                  <TableColumn className="w-16 font-extrabold text-white bg-tranparent">
                    Reward
                  </TableColumn>
                </TableHeader>
                <TableBody className="font-popping text-white font-bold">
                  {data.slice(0, 15).map((row, index) => {
                    let prizeIndex = index;
                    let wagered = row.wagered;
                    return (
                      <TableRow
                        key={row.userId.toString()} // Ensure userId is converted to string if necessary
                        className="hover:scale-105 hover:translate-y-1 font-popping transition transform duration-300"
                      >
                        <TableCell className="text-white  font-popping">
                          <span className="text-white">#</span>
                          {index + 1}
                        </TableCell>
                        <TableCell className="pl-2 font-popping text-white  font-bold">
                          <User
                            avatarProps={{
                              src:
                                row.avatar ===
                                  "/assets/csgo/avatar-anonymous.png" ||
                                row.avatar ===
                                  "https://avatars.steamstatic.com/null_full.jpg" ||
                                row.avatar === "" ||
                                !row.avatar
                                  ? "/brands/nickel_pfp.webp"
                                  : row.avatar,
                            }}
                            name={row.name}
                          />
                        </TableCell>
                        <TableCell className="text-white  font-bold ">
                          <Image
                            src={currencyImageSrc}
                            alt="Currency Image"
                            width={24}
                            height={24}
                            className="pr-1 inline-block"
                          />
                          {wagered.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </TableCell>
                        <TableCell>
                          {prize[prizeIndex] && prize[prizeIndex] !== "0" && (
                            <div className="flex items-center">
                              <Chip
                                startContent={
                                  <Image
                                    src={currencyImageSrc}
                                    alt="Currency Image"
                                    width={24}
                                    height={24}
                                    className="pr-1 inline-block"
                                  />
                                }
                                variant="faded"
                                color="success"
                                className="flex items-center font-bold justify-center  font-popping"
                              >
                                {prize[prizeIndex]}
                              </Chip>
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={closeModal}>
                Close
              </Button>
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};
