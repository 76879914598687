"use client";
import Image from "next/image";
import { LeaderboardEntry } from "../../[site]/components/_types";

type LeaderboardProps = {
  data: LeaderboardEntry[];
  prize: string[];
  website: string; // Add website as a prop
};

export default function NewLeaderboardTable({
  data,
  prize,
  website,
}: LeaderboardProps) {
  const currencyImageSrc =
    website === "roobet"
      ? "/currency/roobet_gem.svg"
      : website === "clash"
        ? "/gems/gif/red.gif"
        : website === "bcgames"
          ? "/currency/bcgames.webp"
          : website === "cases"
            ? "/currency/cases.svg"
            : "/gems/gif/yellow.gif";

  return (
    <div className="flex justify-center font-blackops items-center w-full pt-6 pb-8">
      <div className="w-full max-w-4xl">
        <div className="p-4 mb-4 font-bold bg-transparent rounded-t-lg">
          <div className="grid grid-cols-4 font-extrabold text-xs md:text-base text-white">
            <div className="">Place</div>
            <div className="">User</div>
            <div className="">Reward</div>
            <div className="">Wagered</div>
          </div>
        </div>
        <div className="space-y-4 text-white font-popping">
          {data.map((row, index) => {
            const prizeIndex = index + 3; // Adjust index to match the prize array
            const wagered = row.wagered;
            const avatarSrc =
              row.avatar === "/assets/csgo/avatar-anonymous.png" ||
              row.avatar === "https://avatars.steamstatic.com/null_full.jpg" ||
              row.avatar === "" ||
              row.avatar === "/assets/common/avatar-anonymous.png"
                ? "brands/nickel_pfp.webp"
                : row.avatar;

            return (
              <div
                key={row.userId}
                className="p-4 bg-black shadow-red text-white rounded-lg border border-gray-700 grid grid-cols-4 text-xs md:text-base items-center hover:scale-105 hover:translate-y-1 transition transform duration-300"
              >
                <div className="text-white text-lg">
                  <span className="text-red-900">#</span>
                  {index + 4}
                </div>
                <div className="relative flex items-center z-10">
                  <img
                    src={avatarSrc}
                    alt="User Avatar"
                    width={32}
                    height={32}
                    className="rounded-full"
                  />
                  <span className="ml-2 text-white truncate max-w-[6rem] md:max-w-none">
                    {row.name}
                  </span>
                </div>
                <div className="flex items-center">
                  {prize[prizeIndex] && prize[prizeIndex] !== "0" && (
                    <div className="flex items-center">
                      <Image
                        src={currencyImageSrc}
                        alt="Reward Image"
                        width={24}
                        height={24}
                        className="pr-1 inline-block"
                      />
                      {prize[prizeIndex]}
                    </div>
                  )}
                </div>
                <div className="flex items-center">
                  <Image
                    src={currencyImageSrc}
                    alt="Currency Image"
                    width={24}
                    height={24}
                    className="pr-1 inline-block"
                  />
                  {wagered.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
